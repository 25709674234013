import clsx from 'clsx'
import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router'

import ArrowRight from '@/assets/images/icon-arrow-right-fill.svg'
import { isLoadingArchiveItemsState } from '@/components/archives/ArchivesList'
import useIsMobile from '@/hooks/useIsMobile'
import useRecordList from '@/hooks/useRecordList'
import { ConvertedArchiveItem } from '@/types/archives'
import { ConvertedImageItem } from '@/types/images'
import { pickImageSource } from '@/utils/convert'
import { useRecoilState } from 'recoil'

type Props = {
  item: ConvertedArchiveItem
  images: Array<ConvertedImageItem>
}

const ArchivesItem = ({ item, images }: Props) => {
  const [isLoadingArchiveItems, setIsLoadingArchiveItems] = useRecoilState(
    isLoadingArchiveItemsState
  )

  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const { data: recordList } = useRecordList({})

  useEffect(() => {
    if (!recordList || !images) return

    setIsLoadingArchiveItems(false)
  }, [recordList, images])

  if (!recordList || !images) return <></>

  const { title, category_main, category_sub, desc } = item

  const imageSources = images.filter((image) =>
    item.images.includes(image.search_name)
  )

  const imageSource = pickImageSource(imageSources[0])

  const handleMoreInfoClick = () => {
    const isArchiveItem =
      item.category_main === '인물' && item.category_sub === '구술'

    // 아카이브의 경우
    if (!isArchiveItem) {
      navigate('/archives/list?uid=' + item.uid)

      return
    }

    // 대덕구사람들(레코드)의 경우
    const recordInfo = recordList.find(
      (recordItem) => recordItem.interviewee === item.address
    )

    if (recordInfo) {
      navigate(`/records?uid=${recordInfo.uid}`)
    }
  }

  return (
    <ArchivesItem.Container onClick={handleMoreInfoClick}>
      <ArchivesItem.Header>
        <div className="text-[16px] leading-[22.4px] font-bold color-primary w-[70%] whitespace-pre-wrap break-keep hover-text-target">
          {title}
        </div>

        <div className="flex items-center">
          <ArchivesItem.CategoryBadge>
            {category_main}
          </ArchivesItem.CategoryBadge>

          {/* {category_sub && (
            <>
              <img className="w-[12px] h-[12px]" src={CaretRight} />
              <ArchivesItem.CategoryBadge>
                {category_sub}
              </ArchivesItem.CategoryBadge>
            </>
          )} */}
        </div>
      </ArchivesItem.Header>

      <ArchivesItem.ContentsContainer>
        {imageSource && <ArchivesItem.Image src={imageSource} />}

        <ArchivesItem.DescriptionContainer>
          <p
            className={clsx(
              'text-[16px] maxmd:text-[14px] leading-[24px] maxmd:leading-[1.5em] text-[#6c6c6c] font-normal',
              'h-[calc(24px*5)] line-clamp-5 maxmd:line-clamp-3 maxmd:h-[calc(24px*3)]'
            )}>
            {isMobile && (
              <>
                {imageSource
                  ? desc.slice(0, 50) + ' ...'
                  : desc.slice(0, 75) + ' ...'}
              </>
            )}

            {!isMobile && desc}
          </p>

          <span
            className="color-primary text-[14px] font-bold leading-[19.6px] flex items-center cursor-pointer"
            onClick={handleMoreInfoClick}>
            더 보기
            <img className="ml-[4px] w-[12px] h-[12px]" src={ArrowRight} />
          </span>
        </ArchivesItem.DescriptionContainer>
      </ArchivesItem.ContentsContainer>
    </ArchivesItem.Container>
  )
}

ArchivesItem.Container = ({
  children,
  onClick = () => {},
}: {
  children: ReactNode
  onClick?: () => void
}) => {
  return (
    <div
      className={clsx(
        'w-[620px] maxmd:w-[calc(100vw-40px)] cursor-pointer hover-text',
        'py-[16px] px-[20px]',
        'border-b-[1px] border-r-[1px] border-primary'
      )}
      onClick={onClick}>
      {children}
    </div>
  )
}

ArchivesItem.Header = ({ children }: { children: ReactNode }) => {
  return (
    <h1 className="flex items-center justify-between border-b-[1px] border-[#e9e9e9] maxmd:pb-[8px] pb-[12px]">
      {children}
    </h1>
  )
}

ArchivesItem.CategoryBadge = ({ children }: { children: ReactNode }) => {
  return (
    <span className="rounded-[14px] whitespace-nowrap bg-primary color-white flex items-center justify-center text-[12px] leaeding-[14.4px] font-bold px-[6px] pt-[2px] pb-[3px]">
      {children}
    </span>
  )
}

ArchivesItem.ContentsContainer = ({ children }: { children: ReactNode }) => {
  return <div className="flex mt-[12px]">{children}</div>
}

ArchivesItem.Image = ({ src }: { src: string }) => {
  return (
    <img
      className="w-[240px] h-[170px] maxmd:w-[100px] maxmd:h-[100px]"
      src={src}
    />
  )
}

ArchivesItem.DescriptionContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="ml-[24px] maxmd:ml-[12px] min-h-[170px] maxmd:min-h-[100px] flex flex-col justify-between items-end">
      {children}
    </div>
  )
}

export default ArchivesItem
