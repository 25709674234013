import useRecordList from '@/hooks/useRecordList'
import { recordsSearchKeywordState } from '@/pages/Records'
import { ConvertedRecordItem } from '@/types/record'
import clsx from 'clsx'
import { useRecoilValue } from 'recoil'
import RecordsItem from './RecordsItem'

const RecordsList = () => {
  const recordSearchKeyword = useRecoilValue(recordsSearchKeywordState)

  const { data: recordList, isLoading } = useRecordList({
    searchKeyword: recordSearchKeyword,
  })

  if (!recordList || isLoading) return <></>

  return (
    <ul
      className={clsx(
        'md:w-[calc(310px*4)]',
        'mx-auto py-[40px] maxmd:px-[20px] maxmd:py-[40px]',
        'flex flex-wrap justify-start'
      )}>
      {(recordList as unknown as Array<ConvertedRecordItem>).map(
        (recordItem, index) => (
          <RecordsItem
            key={`${recordItem.uid}-${index}`}
            recordItem={recordItem}
          />
        )
      )}
    </ul>
  )
}

export default RecordsList
