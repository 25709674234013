import clsx from 'clsx'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import { FilterKey } from '@/hooks/useArchives'
import useIsMobile from '@/hooks/useIsMobile'
import useModelCategory from '@/hooks/useModelCategory'

import CaretBottom from '@/assets/images/ico-tip-bottom.svg'
import CaretTop from '@/assets/images/ico-tip-top.svg'
import FilterIcon from '@/assets/images/icon-filter.svg'
import ListIcon from '@/assets/images/icon-list.svg'
import MapIcon from '@/assets/images/icon-map.svg'
import SearchIconX from '@/assets/images/icon-search-x.svg'
import SearchIcon from '@/assets/images/icon-search.svg'
import RemoveIcon from '@/assets/images/icon-x.svg'
import Checkbox from '@/components/common/Checkbox'

const ARCHIVE_NAV_PATHS = {
  MAP: '/archives/map',
  LIST: '/archives/list',
}

export const selectedFiltersState = atom<
  Array<{
    name: string
    categoryId: number
    categoryKey: FilterKey
  }>
>({
  key: 'selectedFiltersState',
  default: [],
})

export const archiveSearchKeywordState = atom({
  key: 'archiveSearchKeywordState',
  default: '',
})

export const hasArchiveFilterOpenedState = atom({
  key: 'hasArchiveFilterOpenedState',
  default: false,
})

const ArchivesNav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useIsMobile()

  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFiltersState)
  const [archiveSearchKeyword, setArchiveSearchKeyword] = useRecoilState(
    archiveSearchKeywordState
  )
  const [hasFilterOpened, setHasFilterOpened] = useRecoilState(
    hasArchiveFilterOpenedState
  )
  const [searchKeyword, setSearchKeyword] = useState('')

  const isMapView = location.pathname.includes(ARCHIVE_NAV_PATHS.MAP)
  const isListView = location.pathname.includes(ARCHIVE_NAV_PATHS.LIST)

  const handleClickListButton = () => navigate(ARCHIVE_NAV_PATHS.LIST)
  const handleClickMapButton = () => navigate(ARCHIVE_NAV_PATHS.MAP)
  const handleFilterLabelClick = () => setHasFilterOpened((prev) => !prev)

  // 검색어 동기화
  useEffect(() => {
    if (searchKeyword.length >= 1) return

    setSearchKeyword(archiveSearchKeyword)
  }, [archiveSearchKeyword])

  // 글로벌 상태 초기화
  useEffect(() => {
    return () => {
      setArchiveSearchKeyword('')
      setSelectedFilters([])

      setHasFilterOpened(false)
    }
  }, [])

  // 검색어가 비워지면 아카이브 리스트 초기화
  useEffect(() => {
    if (searchKeyword.length < 1) {
      setArchiveSearchKeyword('')
    }
  }, [searchKeyword])

  return (
    <nav
      className={clsx(
        'flex flex-col w-[100vw]',
        hasFilterOpened ? 'border-primary' : 'border-transparent',
        'border-b-[1px]',
        'fixed top-[72px] maxmd:top-[52px] left-0 z-10'
      )}>
      {/* 검색바 */}
      <div className="flex">
        <div className="relative flex flex-1">
          <input
            className="w-full pl-[20px] outline-none focus:bg-[#f0f0f0]"
            type="text"
            placeholder={
              isMobile
                ? '자료명을 입력하세요'
                : '검색하려는 자료명을 입력하세요'
            }
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (searchKeyword.length < 1) return

              if (e.key === 'Enter') {
                setArchiveSearchKeyword(searchKeyword)
              }
            }}
          />

          {searchKeyword.length >= 1 && (
            <img
              className="absolute right-[20px] top-1/2 -translate-y-1/2 w-[16px] h-[16px]"
              src={SearchIconX}
              onClick={() => {
                setSearchKeyword('')
                setArchiveSearchKeyword('')
              }}
            />
          )}
        </div>

        <ArchivesNav.Button
          className="w-[calc(100vw/8)] justify-center color-white bg-primary maxmd:w-[110px]"
          onClick={() => {
            setArchiveSearchKeyword(searchKeyword)
          }}>
          <img className="w-[16px] h-[16px] mr-[8px]" src={SearchIcon} />
          검색
        </ArchivesNav.Button>
      </div>

      {/* 필터바 */}
      <div className="flex border-t-[1px] border-b-[1px] border-primary bg-white">
        {isMobile ? (
          <div
            className={clsx(
              'flex-1 flex items-center justify-between pl-[12px] pr-[16px]',
              'text-[14px] leading-[19.6px] font-bold color-primary',
              'maxmd:border-r-[1px] border-primary'
            )}
            onClick={handleFilterLabelClick}>
            <span>필터</span>
            <img src={FilterIcon} />
          </div>
        ) : (
          <>
            <ArchivesNav.FilterLabel
              onClick={handleFilterLabelClick}
              label="주제 분류"
            />
            <ArchivesNav.FilterLabel
              onClick={handleFilterLabelClick}
              label="위치 분류"
            />
            <ArchivesNav.FilterLabel
              onClick={handleFilterLabelClick}
              label="시기 분류"
            />
          </>
        )}

        <div className="flex flex-1 maxmd:flex-none">
          <ArchivesNav.Button
            className={clsx(
              'justify-center flex-1 maxmd:flex-none',
              isListView
                ? 'bg-light-gray color-primary'
                : 'bg-white color-primary grayscale-0 border-r-[1px] border-primary',
              'maxmd:w-[55px]'
            )}
            onClick={handleClickListButton}>
            <img
              className="w-[20px] h-[20px] mr-[8px] maxmd:mr-[0px]"
              src={ListIcon}
            />
            {!isMobile && '목록보기'}
          </ArchivesNav.Button>

          <ArchivesNav.Button
            className={clsx(
              'justify-center flex-1 maxmd:flex-none',
              isMapView
                ? 'bg-light-gray color-primary'
                : 'bg-white color-primary grayscale-0 border-l-[1px] border-primary',
              'maxmd:w-[55px]'
            )}
            onClick={handleClickMapButton}>
            <img className="w-[20px] h-[20px] mr-[8px]" src={MapIcon} />
            {!isMobile && '지도보기'}
          </ArchivesNav.Button>
        </div>
      </div>
      {hasFilterOpened && (
        <div className="w-[100vw] absolute top-[100%] z-40 bg-light-gray border-b-[1px] border-primary">
          {/* 필터 */}
          <Filters />

          {/* 선택된 필터들 */}
          {!isMobile && selectedFilters.length >= 1 && <SelectedFilters />}
        </div>
      )}
      {isMobile && !hasFilterOpened && selectedFilters.length >= 1 && (
        <SelectedFilters />
      )}
    </nav>
  )
}

ArchivesNav.FilterLabel = ({
  label,
  onClick = () => {},
}: {
  label: string
  onClick: () => void
}) => {
  const hasFilterOpened = useRecoilValue(hasArchiveFilterOpenedState)

  return (
    <div
      className={clsx(
        'flex items-center justify-between w-[calc(100vw/4)] px-[20px] cursor-pointer',
        'text-[16px] leading-[22.4px] font-bold color-primary',
        'border-r-[1px] border-primary'
      )}
      onClick={onClick}>
      {label}{' '}
      {hasFilterOpened ? (
        <img className="w-[16px] h-[16px]" src={CaretTop} />
      ) : (
        <img className="w-[16px] h-[16px]" src={CaretBottom} />
      )}
    </div>
  )
}

ArchivesNav.FilterContainer = ({ children }: { children: ReactNode }) => {
  return <div className="flex maxmd:flex-col">{children}</div>
}

ArchivesNav.FilterList = ({
  filters,
  className = '',
}: {
  filters: Array<Filter>
  className?: string
}) => {
  return (
    <ul
      className={clsx(
        'flex-1 max-w-[calc(100vw/4)] maxmd:max-w-full',
        'border-r-[1px] border-primary [&:last-child]:border-r-[0px] maxmd:border-r-[0px]',
        'maxmd:border-b-[1px] border-primary',
        'p-[20px]',
        'flex flex-col flex-wrap h-[200px]',
        'overflow-hidden',
        className
      )}>
      {filters ? (
        filters.map((filter, index) => {
          const isSixthElement = (index + 1) % 6 === 0

          return (
            <ArchivesNav.FilterItem
              className={clsx(!isSixthElement && 'mb-[8px]')}
              key={`${index}-${filter.categoryId}`}
              filter={filter}
              filters={filters}></ArchivesNav.FilterItem>
          )
        })
      ) : (
        <></>
      )}
    </ul>
  )
}

export interface Filter {
  name: string
  categoryId: number
  categoryKey: FilterKey
}

ArchivesNav.FilterItem = ({
  filter,
  filters,
  className = '',
}: {
  filter: Filter
  filters: Array<Filter>
  className?: string
}) => {
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFiltersState)

  const handleFilterClick = (filter: Filter) => () => {
    const hasAlreadySelected = Boolean(
      selectedFilters.find(
        (selectedFilter) => selectedFilter.categoryId === filter.categoryId
      )
    )

    const selectFilter = (filter: Filter) => {
      setSelectedFilters((prev) => [...prev, filter])
    }

    const unselectFilter = (filter: Filter) => {
      setSelectedFilters((prev) =>
        [...prev].filter(
          (selectedFilter) => selectedFilter.categoryId !== filter.categoryId
        )
      )
    }

    if (filter.name === '전체') {
      if (hasAlreadySelected) {
        // 모든 필터를 제거
        setSelectedFilters((prev) => [])
      } else {
        // 모든 필터를 추가
        setSelectedFilters(filters)
      }
    }

    if (hasAlreadySelected) {
      unselectFilter(filter)
    } else {
      selectFilter(filter)
    }
  }

  const isSelected = Boolean(
    selectedFilters.find(
      (selected) => selected.categoryId === filter.categoryId
    )
  )

  return (
    <li
      className={clsx(
        'flex flex-wrap items-center maxmd:w-[50%] cursor-pointer',
        className
      )}
      onClick={handleFilterClick(filter)}>
      <input type="checkbox" checked={isSelected} onChange={() => {}} hidden />

      <Checkbox isSelected={isSelected} />

      <span className="text-[14px] leading-[19.6px] color-primary ml-[8px] whitespace-nowrap select-none">
        {filter.name}
      </span>
    </li>
  )
}

ArchivesNav.Button = ({
  children,
  className = '',
  onClick = () => {},
}: {
  children: ReactNode
  className?: string
  onClick?: () => void
}) => {
  return (
    <button
      className={clsx(
        'h-[50px] flex items-center',
        'leading-[22.4px] text-[16px] font-bold',
        className
      )}
      onClick={onClick}>
      {children}
    </button>
  )
}

const Filters = () => {
  const isMobile = useIsMobile()
  const { data: archiveCategories } = useModelCategory('archives')

  if (!archiveCategories) return <></>

  const mainCategories = archiveCategories['category_main']
  const locationCategories = archiveCategories['location_category']
  const periodCategories = archiveCategories['period_category']

  return (
    <ArchivesNav.FilterContainer>
      <ArchivesNav.FilterList
        className="maxmd:h-[150px]"
        filters={mainCategories}
      />
      <ArchivesNav.FilterList filters={locationCategories} />
      <ArchivesNav.FilterList filters={periodCategories} />
      {!isMobile && <ArchivesNav.FilterList filters={[]} />}
    </ArchivesNav.FilterContainer>
  )
}

const SelectedFilters = () => {
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFiltersState)

  const handleSelectedFilterItemClick = (categoryId: number) => () => {
    setSelectedFilters((prev) =>
      [...prev].filter(
        (selectedFilter) => selectedFilter.categoryId !== categoryId
      )
    )
  }

  const handleInitializeButtonClick = () => {
    setSelectedFilters((prev) => [])
  }

  return (
    <ul
      className={clsx(
        'border-t-[1px] border-primary',
        'maxmd:border-t-[0px] maxmd:border-b-[1px] maxmd:bg-[#f0f0f0]',
        'flex flex-wrap',
        'px-[20px] pt-[11px] pb-[calc(13px-8px)]'
      )}>
      <SelectedFilters.Item
        name={'초기화'}
        onClick={handleInitializeButtonClick}
        reverseTheme={true}
      />

      {selectedFilters.map((selectedFilter) => {
        return (
          <SelectedFilters.Item
            key={`selected-${selectedFilter.categoryId}`}
            name={selectedFilter.name}
            onClick={handleSelectedFilterItemClick(selectedFilter.categoryId)}
          />
        )
      })}
    </ul>
  )
}

SelectedFilters.Item = ({
  name,
  onClick = () => {},
  reverseTheme = false,
}: {
  name: string
  onClick: () => void
  reverseTheme?: boolean
}) => {
  return (
    <li
      className={clsx(
        'mb-[8px]',
        'mr-[8px] [&:last-child]:mr-[0px]',
        'rounded-[50px]',
        'text-[14px] leading-[19.6px]',
        'py-[2px] px-[8px]',
        'flex items-center',
        'whitespace-nowrap',
        'cursor-pointer',
        reverseTheme
          ? 'bg-transparent color-primary border-[1px] border-primary'
          : 'bg-primary color-white'
      )}
      onClick={onClick}>
      {name}

      {!reverseTheme && (
        <img className="w-[12px] h-[12px] ml-[5px]" src={RemoveIcon} />
      )}
    </li>
  )
}

export default ArchivesNav
