import { recordViewTypeState } from '@/pages/Records'
import { useAppDispatch } from '@/states/store'
import clsx from 'clsx'
import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import Footer from './Footer'
import Header from './Header'

type LayoutProps = {
  full?: boolean | undefined
  noFooter?: boolean | undefined
  children?: ReactNode
  overflow?: 'auto' | 'hidden'
  mainClass?: string
}

const Layout = ({
  full,
  overflow,
  noFooter,
  children,
  mainClass = '',
}: LayoutProps) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const recordViewType = useRecoilValue(recordViewTypeState)

  // Effect
  useEffect(() => {
    const isDetailView = searchParams.get('uid')
    const isMapView =
      recordViewType === 'map' || location.pathname.includes('map')

    document.body.style.overflow = isDetailView || isMapView ? 'hidden' : 'auto'
  }, [location.pathname, searchParams.get('uid')])

  useEffect(() => {
    if (!overflow) return

    document.body.style.overflow = overflow
  }, [overflow])

  useEffect(() => {
    // @ts-ignore
    if (!window.previousLocationSearch && !location.search) {
      // Scroll to top
      document.body.scrollTop = 0
    }

    // @ts-ignore
    window.previousLocationSearch = location.search
  }, [location])

  return (
    <div id={'wrap'}>
      {/* {location.pathname === '/' && (
        <div
          id="top-banner"
          className="sticky top-0 pt-5 pb-5 text-center bg-primary color-white lg:pt-4">
          <div className="text-2xl lg:text-[32px] leading-10 lg:leading-[45px] font-bold">
            홈페이지 개편 진행 중입니다.
          </div>
          <div className="text-lg leading-[30px] hidden lg:block">
            보다 좋은 서비스를 제공하기 위해 홈페이지 개편 중에 있습니다.{' '}
          </div>
        </div>
      )} */}
      <Header />
      <main
        id="main"
        className={clsx(!full ? 'container mx-auto' : '', mainClass)}>
        {children}
      </main>
      {!noFooter ? <Footer /> : <></>}
    </div>
  )
}

export default Layout
